<template>
  <v-dialog
    v-model="showModal"
    width="700"
    persistent
    class="create-job-sidebar"
    right
  >
    <v-card
      color="#fff"
      class="pa-3"
    >
      <v-icon
        class="close-btn"
        @click="showModal = false"
      >
        mdi-close
      </v-icon>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <h2 class="title-style pt-3">
          <v-text-field
            v-model="jobTitle"
            :rules="[(v) => !!v || 'This field is required']"
          />
        </h2>
        <p class="sub-heading-creation">
          {{ description }}
        </p>
      </v-form>
      <v-tabs v-model="tab">
        <v-tab>Details</v-tab>
        <v-tab>
          Comments
          <span class="comment_notification">{{ jobComments.length }}</span>
        </v-tab>
        <v-tab>Sub Jobs</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-form
            ref="form"
            v-model="isFormValid"
          >
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-space-between job-dates-progress">
                    <div class="d-flex">
                      <div class="mr-3 border-right">
                        <p class="mb-1">
                          Planned Start Date
                        </p>
                        <p class="mb-1">
                          <b>{{ plannedStartDate }}</b>
                        </p>
                      </div>
                      <div class="border-right">
                        <p class="mb-1">
                          Planned End Date
                        </p>
                        <p class="mb-1">
                          <b>{{ plannedEndDate }}</b>
                        </p>
                      </div>
                    </div>
                    <div class="job-progress">
                      <p class="mb-1">
                        Progress
                      </p>
                      <div class="d-flex">
                        <v-slider
                          v-model="progress"
                          thumb-label="always"
                          :thumb-size="24"
                        />
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="description"
                    outlined
                    dense
                    label="Description"
                    class="field-style"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="jobType"
                    :items="jobTypeList"
                    disabled
                    label="Select Job Type*"
                    :rules="[(v) => !!v || 'This field is required']"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="startDialog"
                    v-model="startDateDialog"
                    :return-value.sync="startDate"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="plannedStartDate"
                        label="Planned Start Date*"
                        :rules="[(v) => !!v || 'This field is required']"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        class="field-style"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="plannedStartDate"
                      scrollable
                      color="#37474F"
                      :min="minStartDate"
                      @input="$refs.startDialog.save(date)"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="startDateDialog = false"
                      >
                        Cancel
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="endDialog"
                    v-model="endDateDialog"
                    :return-value.sync="endDate"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="plannedEndDate"
                        label="Planned End Date*"
                        :rules="[(v) => !!v || 'This field is required']"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        class="field-style"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="plannedEndDate"
                      scrollable
                      color="#37474F"
                      :min="minStartDate"
                      @input="$refs.endDialog.save(date)"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="endDateDialog = false"
                      >
                        Cancel
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="startActualDialog"
                    v-model="startActualDateDialog"
                    :return-value.sync="astartDate"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="actualStartDate"
                        label="Actual Start Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        class="field-style"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="actualStartDate"
                      scrollable
                      color="#37474F"
                      :min="minStartDate"
                      @input="$refs.startActualDialog.save(date)"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="startActualDateDialog = false"
                      >
                        Cancel
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="endActualDialog"
                    v-model="endActualDateDialog"
                    :return-value.sync="aendDate"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="actualEndDate"
                        label="Actual End Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        class="field-style"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="actualEndDate"
                      scrollable
                      color="#37474F"
                      :min="minStartDate"
                      @input="$refs.endActualDialog.save(date)"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="endActualDateDialog = false"
                      >
                        Cancel
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="parentJobId"
                    item-text="name"
                    item-value="id"
                    :items="filteredJobList"
                    :disabled="isParentJobExist || checkType"
                    label="Select Parent Job"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="amount"
                    label="Amount"
                    type="number"
                    outlined
                    dense
                    clearable
                    class="field-style"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="companyId"
                    item-text="company.name"
                    item-value="company.id"
                    :items="projectCompanies"
                    label="Assign a Company"
                    :disabled="jobDetails.company_id !== null"
                    clearable
                    outlined
                    dense
                  />
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="memberId"
                    :item-text="memberName"
                    item-value="app_user_organization_id"
                    :items="CompanyUsers"
                    label="Assign a Member"
                    :disabled="jobDetails.assigned_to_id !== null"
                    clearable
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="status"
                    :items="statusList"
                    label="Select a Status*"
                    :rules="[(v) => !!v || 'This field is required']"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="priority"
                    :items="priorityList"
                    label="Select Priority*"
                    :rules="[(v) => !!v || 'This field is required']"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn
                  class="mt-2"
                  color="#000"
                  :disabled="jobDetails.job_type === 'published' || checkMemberIsAssigned"
                  @click="updateJob()"
                >
                  Update
                </v-btn>
                <v-btn
                  class="mt-2"
                  color="#ccc"
                  @click="showModal = false"
                >
                  cancel
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-tab-item>
        <v-tab-item class="comment-section">
          <p v-if="jobComments.length === 0">
            There is no comment to display.
          </p>
          <div
            v-else
          >
            <v-textarea
              v-model="comment"
              outlined
              class="mt-3"
              placeholder="Type your comment"
              @keyup.enter="addComment()"
            >
              <template #prepend-inner>
                <div
                  :style="imageUrl !== '' ? 'width:100px' : 'width:0px'"
                >
                  <v-icon
                    v-if="imageUrl !== ''"
                    class="clear-image-icon"
                    @click="clearImage"
                  >
                    mdi-close-circle-outline
                  </v-icon>
                  <v-img
                    style="width:100px;height:100px;border-radius: 5px"
                    :src="imageUrl"
                  />
                </div>
              </template>
            </v-textarea>
            <span class="input-controls">
              <v-btn
                id="btn"
                color="transparent"
                elevation="0"
                fab
                @click="addAttachment()"
              >
                <v-icon color="blue">
                  mdi-attachment
                </v-icon>
              </v-btn>
              <v-btn
                id="btn"
                x-small
                color="green"
                elevation="0"
                fab
                :disabled="disableUpdateButton"
                @click="addComment()"
              >
                <v-icon color="white">
                  mdi-send
                </v-icon>
              </v-btn>
            </span>
            <v-file-input
              ref="fileInput"
              v-model="uploadImages"
              hide-input
              type="file"
              truncate-length="15"
              style="display: none;"
              class="upload_btn"
              accept=".jpg, .jpeg, .png, .pdf"
              @click="addAttachment()"
              @change="onFileChange"
            /><br>
            <div class="comment-area">
              <div
                v-for="jobcomment in jobComments"
                :key="jobcomment.id"
              >
                <div
                  class="comment-section-inner"
                  :class="{ 'text-to-right': checkUser(jobcomment.sent_user.external_id) }"
                >
                  <div class="message_bubble_left text-left">
                    <p class="sent_time">
                      {{ jobcomment.sent_user.first_name }} {{ jobcomment.sent_user.last_name }}
                    </p>
                    <div v-if="jobcomment.document_file">
                      <img
                        :src="getImageUrl(jobcomment.document_file.file_path)"
                        :alt="jobcomment.document_file.original_file_name"
                        class="comment-image"
                      >
                    </div>
                    <h4
                      :class="{ 'text-left': checkUser(jobcomment.sent_user.external_id) }"
                    >
                      {{ jobcomment.comment }}
                    </h4>
                  </div>
                  <p class="sent_time">
                    {{ jobcomment.created_at | fromNow }}
                  </p>
                </div>
              </div>
            </div>

            <!-- {{ uploadImages.document_file }}
            <v-btn
              id="btn"
              color="#000"
              :disabled="disableUpdateButton"
              @click="addComment"
            >
              Send
            </v-btn> -->
            <!-- {{ jobComments }} -->
          </div>
        </v-tab-item>
        <v-tab-item>
          <div v-if="subJobs.length > 0">
            <div
              v-for="subjob in subJobs"
              :key="subjob.id"
              class="subjob"
            >
              <h4>{{ subjob.name }}</h4>
              <div>
                <!-- <v-btn
                icon
                color="#000"
                class="btn-action"
                @click="openSubJobDetails(subjob.id)"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>-->
                <v-btn
                  icon
                  color="#000"
                  class="btn-action"
                  :disabled="jobDetails.job_type === 'published'"
                  @click="showDeleteDialog(subjob.external_id)"
                >
                  <v-icon color="red">
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div
            v-else
            class="no-subjobs"
          >
            <v-icon
              size="25"
              color="#ccc"
            >
              mdi-information-outline
            </v-icon>
            No sub jobs
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <centre-spinner :loading="loading" />
    <!-- <edit-job /> -->
    <delete-job
      v-if="showDelete"
      :title="'Delete Job ?'"
      :message="'Action will permanently remove the job from this project, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
  </v-dialog>
</template>
<script>
import moment from 'moment';
import Constants from 'src/constants';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
export default {
  components: {
    'delete-job': DeleteDialog,
    'centre-spinner': spinner,
  },
  filters: {
    fromNow (value) {
      return moment(value).fromNow();
    },
  },
  props: {
    jobId: {
      type: String,
      required: true,
    },
    jobTypes: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      showModal: true,
      jobTitle: '',
      description: '',
      startDate: '',
      endDate: '',
      astartDate: '',
      aendDate: '',
      plannedStartDate: '',
      plannedEndDate: '',
      actualStartDate: '',
      actualEndDate: '',
      progress: '',
      parentJobId: '',
      amount: '',
      companyId: null,
      contractorId: '',
      memberId: null,
      status: '',
      priority: '',
      jobType: '',
      jobTypeList: ['milestone', 'task', 'activity'],
      priorityList: ['low', 'medium', 'high', 'critical'],
      statusList: ['pending', 'completed', 'suspended', 'delayed', 'active'],
      startDateDialog: false,
      endDateDialog: false,
      minStartDate: moment().format('YYYY-MM-DD'),
      date: '',
      jobList: [],
      companyList: [],
      contractorList: [],
      memberList: [],
      tab: null,
      subJobs: [],
      showDelete: false,
      startActualDateDialog: false,
      endActualDateDialog: false,
      deletingJobId: '',
      comment: '',
      uploadImages: {},
      imageUrl: '',
      loading: false,
      isFormValid: false,
    };
  },
  computed: {
    jobDetails () {
      return this.$store.getters['jobs/getJobDetails'];
    },
    disableUpdateButton () {
      return this.jobDetails.job_type === 'draft';
    },
    isParentJobExist () {
      if (this.jobDetails.parent_job_id === null) {
        return false;
      }
      return true;
    },
    JobList () {
      return this.$store.getters['jobs/getJobList'];
    },
    checkMemberIsAssigned () {
      if (this.companyId !== null && this.memberId === null) {
        return true;
      } else {
        return false;
      }
    },
    filteredJobList () {
      if (
        this.jobDetails &&
        this.jobDetails.name &&
        this.jobDetails.sub_jobs?.length
      ) {
        return this.JobList.filter(
          item =>
            item.name !== this.jobDetails.name &&
            !this.jobDetails.sub_jobs.some(subJob => subJob.name === item.name),
        );
      }
      return this.JobList.filter(item => item.name !== this.jobDetails.name);
    },

    CompanyUsers () {
      return this.$store.getters['companies/getCompanyUsers'];
    },
    jobComments () {
      return this.$store.getters['jobs/getJobComments'];
    },
    projectCompanies () {
      return this.$store.getters['projects/getProjectCompanies'];
    },
    currentUser () {
      return this.$store.getters.getUser;
    },
    checkType () {
      return this.jobDetails.type === 'milestone';
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
    companyId (newVal, oldVal) {
      this.getCompanyUsers(newVal);
    },
    tab (newVal, oldVal) {
      if (newVal === 1 && this.jobComments.length !== 0) {
        setTimeout(() => {
         // this.scrollToBottom();
        }, 2000);
      }
    },
    jobComments (newVal, oldVal) {
      if (this.tab === 1 && this.jobComments.length > 0) {
          // this.scrollToBottom();
      }
    },
  },
  async mounted () {
    this.loading = true;
    await this.storeJobId();
    await this.getJobDetails();
    await this.getJobList();
    await this.getProjectCompanies();
    if (this.jobDetails.job_type === 'published') {
      await this.getAllJobComments({ itemsPerPage: 100, pageStart: 0 });
    }
    this.loading = false;
  },
  methods: {
    getImageUrl (filePath) {
      const filep = `/${filePath}`;
      const baseUrl = process.env.VUE_APP_STORAGE_API_URL;
      return `${baseUrl}${filep}`;
    },
    clearImage () {
      this.uploadImages = [];
      this.imageUrl = '';
    },
    createImage (file) {
      const reader = new FileReader();

      reader.onload = e => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange (file) {
      this.uploadImages = file;
      if (!file) {
        return;
      }
      this.createImage(file);
    },
    addAttachment () {
      this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
    },
    async addComment () {
    if (this.comment === '' && Object.keys(this.uploadImages).length === 0) {
      this.$store.dispatch('alert/onAlert', {
            message: 'Please add comments to send',
            type: Constants.ALERT_TYPE_ERROR,
          });
          return;
    }
      const formData = new FormData();
      if (this.uploadImages instanceof File) {
        formData.append('document_file', this.uploadImages);
      } else {
        formData.append('document_file', '');
      }
      formData.append('comment', this.comment);
      formData.append('jobId', this.jobId);
      await this.$store.dispatch('jobs/addJobComment', { formData }).then(response => {
        this.comment = '';
        this.clearImage();
        this.getJobComments({ itemsPerPage: 100, pageStart: 0 });
       // this.scrollToBottom();
      });
    },
    scrollToBottom () {
      if (this.jobComments.length > 0) {
        document.querySelector('.comment-area').lastElementChild.scrollIntoView();
      }
    },
    async getJobComments ({ itemsPerPage, pageStart }) {
      await this.$store
        .dispatch('jobs/fetchJobComments', {
          jobId: this.jobId,
          params: {
            limit: itemsPerPage,
            startPage: pageStart,
          },
        })
        .catch(() => {
          this.loading = false;
        });
      this.loading = false;
    },
    async getAllJobComments ({ itemsPerPage, pageStart }) {
      await this.$store.dispatch('jobs/fetchJobComments', {
        jobId: this.jobId,
        params: {
          limit: itemsPerPage,
          startPage: pageStart,
        },
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    showDeleteDialog (jobid) {
      this.deletingJobId = jobid;
      this.showDelete = true;
    },
    async storeJobId () {
      await this.$store.dispatch('jobs/socketJobId', this.jobId);
    },
    deleteConfirmed () {
      this.loading = true;
      this.showDelete = false;
      this.$store
        .dispatch('jobs/deleteJob', {
          jobId: this.deletingJobId,
        })
        .then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Job Deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.getJobDetails();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openSubJobDetails (subjobid) {
      this.showModal = true;
    },
    memberName (e) {
      return `${e.first_name} ${e.last_name}`;
    },
    async getProjectCompanies () {
      this.loading = true;
      await this.$store
        .dispatch('projects/fetchProjectCompanies', {
          projectId: this.$route.params.projectId,
        })
        .catch(() => {
          this.loading = false;
        });
      this.loading = false;
    },
    async getCompanyUsers (companyid) {
      this.loading = true;
      this.selectedHub = null;
      if (this.jobDetails.company_id === null) {
        this.memberId = null;
      }
      await this.$store
        .dispatch('companies/fetchCompanyUsers', { companyId: companyid })
        .catch(() => {
          this.loading = false;
        });
      this.loading = false;
    },
    async getJobList () {
      this.loading = true;
      this.selectedHub = null;
      await this.$store
        .dispatch('jobs/fetchJobsByProject', {
          projectid: this.$route.params.projectId,
        })
        .catch(() => {
          this.loading = false;
        });
      this.loading = false;
    },
    async getJobDetails () {
      this.loading = true;
      await this.$store
        .dispatch('jobs/JobDetails', {
          jobId: this.jobId,
          jobTypes: this.jobTypes,
        })
        .then(response => {
          this.jobTitle = this.jobDetails.name;
          this.plannedStartDate = this.jobDetails.planned_start_date;
          this.plannedEndDate = this.jobDetails.planned_end_date;
          this.progress = this.jobDetails.progress;
          this.status = this.jobDetails.status;
          this.description = this.jobDetails.description;
          this.jobType = this.jobDetails.type;
          this.parentJobId = this.jobDetails.parent_job_id;
          this.amount = this.jobDetails.amount;
          this.status = this.jobDetails.status;
          this.priority = this.jobDetails.priority;
          this.memberId = this.jobDetails.assigned_to_id;
          this.companyId = this.jobDetails.company_id;
          this.subJobs = this.jobDetails.sub_jobs;
          this.actualStartDate = this.jobDetails.actual_start_date;
          this.actualEndDate = this.jobDetails.actual_end_date;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async updateJob () {
      if (this.$refs.form.validate() === false) {
        this.$store.dispatch(
          'alert/onAlert',
          {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        return;
      }
      this.loading = true;
      await this.$store
        .dispatch('jobs/updateJob', {
          name: this.jobTitle,
          description: this.description,
          planned_start_date: this.plannedStartDate,
          planned_end_date: this.plannedEndDate,
          actual_start_date: this.actualStartDate,
          actual_end_date: this.actualEndDate,
          status: this.status,
          type: this.jobType,
          priority: this.priority,
          assigned_to_id: this.memberId ? this.memberId : this.companyId,
          assigned_to_type: this.memberId === null ? 'company' : 'app-user',
          amount: this.amount,
          parent_job_id: this.parentJobId,
          template_id: this.$route.params.projectId,
          template_type: 'project',
          jobId: this.jobId,
          progress: this.progress.toString(),
        })
        .then(response => {
          this.$emit('success');
          this.$store.dispatch('alert/onAlert', {
            message: 'Job has been updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$store.dispatch('jobs/updateEnablePublish', true);
          this.loading = false;
          this.$emit('success');
          this.showModal = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    checkUser (id) {
      if (this.currentUser.user.external_id === id) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474f;
  margin-left: 20px;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.icon-style {
  color: #37474f;
  font-size: 32px !important;
}

.field-style {
  margin-top: -10px;
}

.create-job-sidebar {
  position: absolute !important;
  right: 0;
  top: 0;
  max-width: 650px;
}

.sub-heading-creation {
  margin-bottom: 6px !important;
  color: #aaa !important;
  margin-left: 21px;
  margin-right: 21px;
}

.job-progress {
  width: 200px;
}

.percentage-style {
  color: #000;
}

.border-right {
  border-right: 1px solid #ccc;
  padding: 5px;
}

.job-dates-progress {
  border-bottom: 1px solid #ccc;
  padding: 5px;
}

.progress-bar-style {
  cursor: pointer;
}

.v-select-list {
  text-transform: capitalize;
}
.v-text-field >>> .v-text-field__details{
  display: none;
}
.upload_btn {
  margin-top: -80px;
  float: right;
  margin-right: 7px;
  background: none !important;
  color: #000 !important;
}
.input-controls{
  float: right;
}
.comment_notification {
  background: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-top: -10px;
  padding: 5px;
}

.subjob {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.text-to-right{
  text-align: right!important;
  padding-right:10px;
}
.btn-action {
  background: #fff !important;
}

.comment-section {
  padding: 15px;
}

.comment-area {
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  width:100%;
}
.message_bubble_left p{
  margin-bottom:4px!important;
}

.message_bubble_left {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 10px;
  max-width: 70%;
  word-wrap: break-word;
  margin: 8px 0;
  font-size: 14px;
  line-height: 1.4;
  background-color: #e0e0e0;
  color: #333;
}

.width-message {
  width: fit-content;
  margin-bottom: 10px;
}

.sent_time {
  font-size: 9px;
}

.clear-image-icon {
  float: right;
}

.comment-image {
  width: 225px;
  border-radius: 5px;
  border: 5px solid #ddd;
}

.close-btn {
  float: right;
}

.no-subjobs {
    text-align: center;
    padding: 30px;
}
</style>
